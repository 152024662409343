import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStoreService } from './local-store.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from './login.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {
  password: string;
  constructor(
    private router: Router,
    private auth: AuthService,
    private store: LocalStoreService,
    public servicelogin: LoginService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const helper = new JwtHelperService();

    let rpta = this.auth.checkAuth();

    if (!rpta) {

      // this.servicelogin.logout()
      return false;

    } else {

      let token = JSON.parse(this.store.getItem(environment.TOKEN_NAME));
      //token = JSON.parse(token)
      if (!helper.isTokenExpired(token.access_token)) {

        /// aqui iria la logica de si la opcion del menu existe en la DB

        var lista = []

        this.password = "15646^&amp;%$3(),>2134bgGz*-+e7hds";
        lista = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("menu"), this.password.trim()).toString(CryptoJS.enc.Utf8));
        lista = lista.filter(a => a.txt_URL_OPC == state.url)

        if (state.url == "/my-profile") {
          return true
        } else if (lista.length > 0) {
          return true
        } else {
          // this.servicelogin.logout()
          return false
        }

      } else {
        // this.servicelogin.logout()
        return false;
      }

    }
  }



}
